import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"weight\":[\"200\"],\"subsets\":[\"latin\"],\"variable\":\"--font-nunito\",\"display\":\"swap\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\"],\"variable\":\"--font-noto-sans\",\"display\":\"swap\"}],\"variableName\":\"notoSansJp\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Serif_JP\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-noto-serif\",\"display\":\"swap\"}],\"variableName\":\"notoSerifJp\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Source_Code_Pro\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-source-code\",\"display\":\"swap\"}],\"variableName\":\"sourceCode\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/TSlogo.svg");
